var $class="se2--header-only-tabs--skip-navigation",$name="HeaderOnlyTabs/skipNavigation",$path="app/components/HeaderOnlyTabs/partials/skipNavigation/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

const focusableElements = 'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled])';

export default shell.registerPartial($this, ({ addEventListener, mount }) => {
  const jumpToSelector = 'main';

  const moveFocusToContent = () => {
    document.querySelector(jumpToSelector).querySelector(focusableElements).focus();
  };

  mount((element) => {
    addEventListener(element, 'click', moveFocusToContent);
  });
});
